import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facebook-popup',
  templateUrl: './facebook-popup.component.html',
  styleUrls: ['./facebook-popup.component.css']
})
export class FacebookPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
