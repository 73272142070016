<!-- Intro Section-->
<section class="view hm-gradient" id="intro">
    
<div class="full-bg-img d-flex align-items-center">
    <div class="container">
    <div class="row no-gutters">
        <div class="col-md-7 col-lg-6 text-center text-md-left margins">
        <div class="white-text">
            <div class="wow fadeInLeft" data-wow-delay="0.3s">
            <h1 class="font-bold mt-sm-5">Your Success is Our Mission.</h1>
            <div class="h6">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem repellendus quasi fuga nesciunt
                dolorum nulla magnam veniam sapiente, fugiat! Commodi sequi non animi ea dolor molestiae
                iste.
            </div>
            </div><br>
            <div class="wow fadeInLeft" data-wow-delay="0.3s">
            <a class="btn btn-white dark-grey-text font-bold ml-0" href="#contact" >Subscribe</a>
            </div>
        </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12" >
        <div class="white-text">
            <div class="wow fadeInLeft" data-wow-delay="0.3s">
            <h1 class="font-bold mt-sm-5">Comming Soon</h1>
                <div id="flipdown" class="flipdown"></div>
            </div>
    
    </div>
    </div>
</div>