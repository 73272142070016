<div class="MainTechnologies">
    <div class="technologies container">
        <div class="Heading">
            <h1 class="headingText"> Integrate with any <span class="afterClass">Website</span></h1>
        </div>

        <div class="content">
            <div class="angularImg contentCard"><img style = "width: 60px;" src = "../../assets/images/angular.png" /></div>
            <div class="reactImg contentCard"><img style = "width: 60px;" src = "../../assets/images/react.png" /></div>
            <div class="laravelImg contentCard"><img style = "width: 60px;" src = "../../assets/images/laravel.png" /></div>
            <div class="mongoImg contentCard"><img style = "width: 60px;" src = "../../assets/images/mongo.png" /></div>
            <div class="expressImg contentCard"><img style = "width: 60px;" src = "../../assets/images/express.png" /></div>
            <div class="nodeImg contentCard"><img style = "width: 60px;" src = "../../assets/images/node.png" /></div>
            <div class="wordpressImg contentCard"><img style = "width: 60px;" src = "../../assets/images/wordpress.png" /></div>
            <div class="bootstrapImg contentCard"><img style = "width: 60px;" src = "../../assets/images/bootstrap.png" /></div>
        </div>
    </div>
</div>