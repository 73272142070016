<section class="text-center py-5 grey lighten-4" id="about">
    <div class="container">
      <div class="wow fadeIn">
        <h2 class="h1 pt-5 pb-3">Web Accessibility Blog</h2>
      
      </div>
      <div class="row">
        <div class="col-md-4 mb-r wow fadeInUp" data-wow-delay=".3s"><i class="fa fa-map-marker fa-3x orange-text"></i>
          <h3 class="h4 mt-3">Design</h3>
          <p class="mt-3 blue-grey-text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti
            hic.
          </p>
        </div>
        <div class="col-md-4 mb-r wow fadeInUp" data-wow-delay=".4s"><i class="fa fa-comments fa-3x cyan-text"></i>
          <h3 class="h4 mt-3">Feedback</h3>
          <p class="mt-3 blue-grey-text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti
            hic.
          </p>
        </div>
        <div class="col-md-4 mb-r wow fadeInUp" data-wow-delay=".5s"><i class="fa fa-cubes fa-3x red-text"></i>
          <h3 class="h4 mt-3">Execution</h3>
          <p class="mt-3 blue-grey-text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti
            hic.
          </p>
        </div>
      </div>
    </div>
  </section>