<section class="py-5" id="team" style="background-color: aqua;">

    <div class="container">
      <div class="wow fadeIn" dir="rtl">
        <h2 class="h1 pt-5 pb-3 text-center">Our Clients</h2>
        <p class="px-5 mb-5 pb-3 lead text-center blue-grey-text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate
          esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.
        </p>
      </div>

      <div class="row mb-lg-4 center-on-small-only" dir="rtl">

        <div class="col-lg-6 col-md-12 mb-r wow fadeInLeft" data-wow-delay=".3s">

          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c4.jpg" alt="Football image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Football</h5>
            </div>
          </div>
          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c5.jpg" alt="Amazon Image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c6.jpg" alt="Amazon image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 mb-r wow fadeInLeft" data-wow-delay=".3s">

          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c2.jpg" alt="Amazon Image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c1.jpg" alt="Amazon Image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c3.jpg" alt="Amazon Image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
        </div>

      </div>

      <div class="row mb-lg-4 center-on-small-only" dir="rtl">

        <div class="col-lg-6 col-md-12 mb-r wow fadeInLeft" data-wow-delay=".3s">

          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c4.jpg" alt="team member"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c5.jpg" alt="team member"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c6.jpg" alt="Amazon Image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 mb-r wow fadeInLeft" data-wow-delay=".3s">

          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c2.jpg" alt="Amazon Image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c1.jpg" alt="Amazon Image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
          <div class="col-md-4 client float-left">
            <img class="img-fluid rounded z-depth-1 mb-3" src="assets/img/c3.jpg" alt="Amazon Image"/>
            <div class="client-data  text-center">
              <h5 class="font-weight-bold">Amazon</h5>
            </div>
          </div>
        </div>

      </div> 
    </div>
    </section>