
<nav class="navbar navbar-expand-lg navbar-dark  fixed-top scrolling-navbar" id="navbar">
    <div class="container">
        <a class="navbar-brand" href="#">
            <img src="assets/img/logo1.png" alt="fixurweb" class="img-fluid" 
         style="height: 60px;width: 150px">
        </a>

      <a href="tel:035440155?" class="d-sm-block d-lg-none  text-white text-left">
          <i class="fa fa-phone  text-white "></i>
      </a>
        
      <a data-toggle="modal" data-target="#popup"  class="d-sm-block d-lg-none  text-white text-left"><i class="fa fa-envelope  text-white "></i></a>


      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
      <div class="collapse text-center navbar-collapse" id="navbarContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item"><a class="nav-link active" href="#about">Solutions</a></li>
          <li class="nav-item"><a class="nav-link" href="#projects">Company</a></li>
          <li class="nav-item"><a class="nav-link" href="#pricing">Compliance</a></li>
          <li class="nav-item"><a class="nav-link" href="#team">Clients</a></li>
          <li class="nav-item"><a class="nav-link" href="#contact">Partners</a></li>
          <li class="nav-item"><a class="nav-link" href="#contact">Pricing</a></li>
          <li class="nav-item"><a class="nav-link" href="#contact">Contact Us</a></li>
        </ul>
        <a class="btn my-0" id="getStarted" href="#" target="_blank">Get Started Free</a>
      
        <div id="google_translate_element"></div>
      </div>
    </div>

  </nav>