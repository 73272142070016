<section id="contact">
    <div class="rgba-black-strong py-5">
      <div class="container">
        <div class="wow fadeIn">
          <h2 class="h1 text-white pt-5 pb-3 text-center">Subscribe Us</h2>
          <p class="text-white px-5 mb-5 pb-3 lead text-center">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate
            esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.
          </p>
        </div>
        <div class="card mb-5 wow fadeInUp" data-wow-delay=".4s">
          <div class="card-body p-5">
            <div class="row">
              <div class="col-md-8">
                <form action="https://formspree.io/youremail@example.com" method="POST">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="md-form">
                        <input class="form-control" id="name" type="text" name="name" required="required"/>
                        <label for="name">Your name</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="md-form">
                        <input class="form-control" id="email" type="text" name="_replyto" required="required"/>
                        <label for="email">Your email</label>
                      </div>
                    </div>
                  </div>
                
                
                </form>
              </div>
              <div class="col-md-4">
                <div class="center-on-small-only mb-4">
                  <button class="btn btn-indigo ml-0" type="submit"><i class="fa fa-paper-plane-o mr-2"></i> Subscribe</button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>