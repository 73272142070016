import { Component, OnInit } from '@angular/core';
// include fix_ur_web plugin here
// declare var start_fix_ur_web_plugin: any;
// import '../../assets/js/fix_ur_web_p.js';
@Component({
  selector: 'app-enable',
  templateUrl: './enable.component.html',
  styleUrls: ['./enable.component.css']
})
export class EnableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //calling plugin
    // start_fix_ur_web_plugin();
  }

}
