<div style="margin-top: 30px;">

</div>
<section class="text-center py-5  darken-1 text-white" id="pricing">
  <div class="container">
    <div class="wow fadeIn">
      <h2 class="h1 pt-5 pb-3">Our pricing plans</h2>
      <p class="px-5 mb-5 pb-3 lead">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi,
        veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.
      </p>
    </div>
    <div class="row wow zoomIn">
      <div class="col-lg-4 col-md-12 mb-r">
        <div class="card card-image">
          <div class="text-white text-center pricing-card d-flex align-items-center rgba-stylish-strong py-3 px-3 rounded">
            <div class="card-body">
              <div class="h5">Individual</div>
              <div class="py-5"><sup class="display-4">$</sup><span class="display-1">0</span><span class="display-4">/m</span></div>
              <ul class="list-unstyled">
                <li>
                  <p><strong>100</strong> features</p>
                </li>
              </ul><a class="btn btn-outline-white mt-5"> Buy now</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mb-r">
        <div class="card card-image">
          <div class="text-white text-center pricing-card d-flex align-items-center rgba-teal-strong py-3 px-3 rounded">
            <div class="card-body">
              <div class="h5">Startup</div>
              <div class="py-5"><sup class="display-4">$</sup><span class="display-1">29</span><span class="display-4">/m</span></div>
              <ul class="list-unstyled">
                
                <li>
                  <p><strong>200</strong> features</p>
                </li>
                
              </ul><a class="btn btn-outline-white mt-5"> Buy now</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mb-r">
        <div class="card card-image">
          <div class="text-white text-center pricing-card d-flex align-items-center rgba-red-strong py-3 px-3 rounded">
            <div class="card-body">
              <div class="h5">Enterprise</div>
              <div class="py-5"><sup class="display-4">$</sup><span class="display-1">99</span><span class="display-4">/m</span></div>
              <ul class="list-unstyled">
                
                <li>
                  <p><strong>Unlimited</strong> features</p>
                </li>
                
              </ul><a class="btn btn-outline-white mt-5"> Buy now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
