<!-- =========== Facebook =========== -->

<div class=" fbapi embed-responsive-16by9"> 
  
    <iframe class="" id="fbframe" 
     src="http://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2FNgeneration11&amp;  
    
    colorscheme=light&amp;
    show_faces=true&amp;border_color&amp;stream=true&amp;header=true&amp;height=435"
     scrolling="yes" frameborder="0" style="overflow:hidden;  height:500px; 
     background: white; float:left; display: none;"></iframe> 
     <button id="btnchange" class="btn fbbtn rounded border-0 "onclick="hidefb();"> Facebook</button>
</div> 