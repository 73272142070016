   <!-- ==== Message Pop Up Icon ===== -->

   <div class="side  rounded  text-center">
    <a  data-toggle="modal" data-target="#popup" class="text-white " dir="rtl">
  
    <svg xmlns="http://www.w3.org/2000/svg" class="svgimg" x="0px" y="0px"
      width="64" height="64"
      viewBox="0 0 172 172"
      style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><path d="M86,172c-47.49649,0 -86,-38.50351 -86,-86v0c0,-47.49649 38.50351,-86 86,-86v0c47.49649,0 86,38.50351 86,86v0c0,47.49649 -38.50351,86 -86,86z" fill="#0077b6"></path><g fill="#ffffff"><path d="M48.27012,32.96667c-10.20009,0 -18.51972,8.31963 -18.51972,18.51972v54.13455c0,10.20009 8.31963,18.51972 18.51972,18.51972h33.19414c-0.28492,-1.85197 -0.42849,-3.76093 -0.42849,-5.69837c0,-0.96872 0.03072,-1.90896 0.14468,-2.84919h-32.91033c-5.49893,0 -9.97215,-4.47322 -9.97215,-9.97215v-41.26869l46.40502,25.0862c1.26758,0.68461 2.79474,0.68461 4.06232,0l46.40502,-25.0862v21.24091c3.13411,1.62404 6.01178,3.70439 8.54756,6.15469v-40.26146c0,-10.20009 -8.31963,-18.51972 -18.51972,-18.51972zM48.27012,41.51423h76.92805c5.49893,0 9.97215,4.47322 9.97215,9.97215v3.14969l-48.43618,26.18247l-48.43618,-26.18247v-3.14969c0,-5.49893 4.47322,-9.97215 9.97215,-9.97215zM118.0752,87.10122c-17.30881,0 -31.34106,14.03225 -31.34106,31.34106c0,17.30881 14.03225,31.34106 31.34106,31.34106c17.30881,0 31.34106,-14.03225 31.34106,-31.34106c0,-17.30881 -14.03225,-31.34106 -31.34106,-31.34106zM118.0752,95.64878c1.57275,0 2.84919,1.27644 2.84919,2.84919v17.09512h17.09512c1.57275,0 2.84919,1.27644 2.84919,2.84919c0,1.57275 -1.27644,2.84919 -2.84919,2.84919h-17.09512v17.09512c0,1.57275 -1.27644,2.84919 -2.84919,2.84919c-1.57275,0 -2.84919,-1.27644 -2.84919,-2.84919v-17.09512h-17.09512c-1.57275,0 -2.84919,-1.27644 -2.84919,-2.84919c0,-1.57275 1.27644,-2.84919 2.84919,-2.84919h17.09512v-17.09512c0,-1.57275 1.27644,-2.84919 2.84919,-2.84919z"></path></g></g></svg>
  
  </a>
 </div>



<!-- ==================== Pop Up Message Form=================== -->

<div class="modal  show" id="popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
<div class="modal-dialog" role="document">
  <div class="modal-content ">
    <div class="ram bg-popup1 pt-3 ">
      
      <h5 class="modal-title  text-center text-white" id="exampleModalLongTitle" >Leave details and a representative will get back to you soon </h5>        <button type="button" class="close  mb-2 mr-2 bg-white px-1 py-1 rounded text-left" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
      </button>
    </div>
  <div class="modal-body bg-popup">
     <form action="/send_email" method="post" role="form" class="php-email-form">
      <div class="container bg-white">
     
        <div class="form-row text-right" >
          <div class="form-group col-md-6">
          <input type="text" id="name" class="form-control text-left"   name="name" placeholder="enter full name" />
          <div class="validate"></div>
          </div>
          <div class="form-group col-md-6 ">
          <input type="email" name="email"  class="form-control text-left"  id="email" placeholder="enter email"  data-rule="email" data-msg="Please enter a valid email" />
          <div class="validate"></div>
          </div>
        </div>
  
        <div class="form-group text-left">
          <input type="tel" class="form-control text-left"  name="phone" id="subject"  placeholder="phone.." data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
          <div class="validate"></div>
        </div>
        <div class="form-group text-left">
          <textarea class="form-control text-left" id="message" name="message"  placeholder="Message.." rows="4" data-rule="required" data-msg="Please write something for us"></textarea>
          <div class="validate"></div>
        </div>
    
       
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">יציאה</button>
        <div class="text-center">
          <button type="submit" class="btn btn-outline-primary2" >  <i class="fa fa-paper-plane fa-flip-horizontal"></i> שלח</button>
        </div>
    </div>
      </div>
        </form>
 
    </div>
</div>
</div>
</div>