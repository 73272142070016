import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { IntroSectionComponent } from './intro-section/intro-section.component';
import { MainSectionComponent } from './main-section/main-section.component';
import { PricingPlansComponent } from './pricing-plans/pricing-plans.component';
import { OurClientsComponent } from './our-clients/our-clients.component';
import { BlogComponent } from './blog/blog.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { MessagePopupComponent } from './message-popup/message-popup.component';
import { FacebookPopupComponent } from './facebook-popup/facebook-popup.component';
import { EnableComponent } from './enable/enable.component';
import { TechnologiesComponent } from './technologies/technologies.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    IntroSectionComponent,
    MainSectionComponent,
    PricingPlansComponent,
    OurClientsComponent,
    BlogComponent,
    SubscribeComponent,
    MessagePopupComponent,
    FacebookPopupComponent,
    EnableComponent,
    TechnologiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
